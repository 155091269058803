
import { Vue, Component } from "vue-property-decorator";
import Editor from "vue2-ace-editor";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import axios from "axios";
import beautify from "js-beautify";
@Component({
  components: { Editor, FileUploaderDialog },
})
export default class Home extends Vue {
  activeTab: number = 0;
  renameFilePath: string | null = null;
  renameFileName: string | null = null;
  activatedItems: Array<string> = [];
  selectedItem: any = null;
  uploaderDialogVisible: boolean = false;
  directoryDialog: any = {
    active: false,
    name: null,
  };
  textFileDialog: any = {
    active: false,
    name: null,
  };
  files: Array<any> = [];
  items: Array<any> = [];
  navigation: any = {
    shown: true,
    width: 400,
    minSize: 100,
  };
  menu: any = {
    show: false,
    x: 0,
    y: 0,
  };
  initiallyOpen: Array<any> = [];
  extensitons: any = {
    html: {
      icon: "mdi-language-html5",
      color: "green",
      editor: "text",
      lang: "html",
    },
    cshtml: { icon: "mdi-at", color: "green", editor: "text", lang: "razor" },
    cs: {
      icon: "mdi-language-csharp",
      color: "pink",
      editor: "image-viewer",
      lang: "csharp",
    },
    js: {
      icon: "mdi-language-javascript",
      color: "pink",
      editor: "text",
      lang: "javascript",
    },
    json: {
      icon: "mdi-code-json",
      color: "pink",
      editor: "text",
      lang: "json",
    },
    md: {
      icon: "mdi-language-markdown",
      color: "green",
      editor: "text",
      lang: "text",
    },
    pdf: { icon: "mdi-file-pdf-box", color: "green", editor: "pdf-viewer" },
    png: { icon: "mdi-file-image", color: "purple", editor: "image-viewer" },
    jpg: { icon: "mdi-file-image", color: "purple", editor: "image-viewer" },
    jpeg: { icon: "mdi-file-image", color: "purple", editor: "image-viewer" },
    webp: { icon: "mdi-file-image", color: "purple", editor: "image-viewer" },
    svg: {
      icon: "mdi-file-image",
      color: "purple",
      editor: "text",
      lang: "xml",
    },
    ico: { icon: "mdi-star", color: "yellow", editor: "image-viewer" },
    txt: {
      icon: "mdi-file-document-outline",
      color: "gray",
      editor: "text",
      lang: "text",
    },
    xls: { icon: "mdi-file-excel", color: "green", editor: "file-viewer" },
    xml: { icon: "mdi-xml", color: "green", editor: "text", lang: "xml" },
    scss: { icon: "mdi-sass", color: "green", editor: "text", lang: "scss" },
    css: {
      icon: "mdi-language-css3",
      color: "green",
      editor: "text",
      lang: "css",
    },
    zip: { icon: "mdi-zip-box", color: "green", editor: "file-viewer" },
  };
  async renameFileOrDirectory(item) {
    try {
      let _url = item.isDirectory
        ? "/manage/filesystem/directory"
        : "/manage/filesystem/file/rename";
      let _updateData = {
        path: item.physicalPath.match(/(.*)[\/\\]/)[1],
        name: item.name,
        newName: this.renameFileName,
      };
      let _result = await axios.patch(_url, _updateData);
      item.name = _result.data.name;
      item.physicalPath = _result.data.physicalPath;
      this.renameFilePath = null;
      this.renameFileName = null;
    } catch (e) {
      throw e;
    }
  }
  get currentDirectoryPath() {
    return this.selectedItem.isDirectory
      ? this.selectedItem.physicalPath
      : this.selectedItem.physicalPath.match(/(.*)[\/\\]/)[1] || "";
  }

  get canRenameFile(): boolean {
    if (
      !this.selectedItem ||
      this.selectedItem.physicalPath == "/Views" ||
      this.selectedItem.physicalPath == "/wwwroot" ||
      this.selectedItem.physicalPath == "/Areas"
    ) {
      return false;
    }
    return true;
  }

  initRename() {
    if (!this.canRenameFile) return;
    this.renameFilePath = this.selectedItem.physicalPath;
    this.renameFileName = this.selectedItem.name;
    Vue.nextTick(() => {
      (this.$refs.fileNameEdit as HTMLElement)?.focus();
    });
  }
  onUpdate(activatedItems) {
    this.activatedItems = activatedItems;
  }

  showContextMenu(item, e) {
    this.selectedItem = item;
    e.preventDefault();
    this.menu.show = false;
    this.menu.x = e.clientX;
    this.menu.y = e.clientY;
    this.$nextTick(() => {
      this.menu.show = true;
    });
  }

  prettifyXml(sourceXml) {
    var xmlDoc = new DOMParser().parseFromString(sourceXml, "application/xml");
    var xsltDoc = new DOMParser().parseFromString(
      [
        // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        "  </xsl:template>",
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        "  </xsl:template>",
        '  <xsl:output indent="yes"/>',
        "</xsl:stylesheet>",
      ].join("\n"),
      "application/xml",
    );

    var xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    if (resultDoc.getElementsByTagName("parsererror").length > 0) {
      throw new Error("Error parsing XML");
      return sourceXml;
    } else {
      var resultXml = new XMLSerializer().serializeToString(resultDoc);
      return resultXml;
    }
  }

  //
  editorInit(editor) {
    require("brace/ext/language_tools"); //language extension prerequsite...
    require("brace/mode/html");
    require("brace/mode/javascript"); //language
    require("brace/mode/sass");
    require("brace/mode/scss");
    require("brace/ext/searchbox")
    require("brace/mode/css");
    require("brace/mode/json");
    require("brace/mode/xml");
    require("brace/mode/razor");
    require("brace/theme/chrome");
    require("brace/snippets/javascript");

    let vm = this;
    editor.getSession().on("change", function () {
      let file = vm.files.find(
        (f) => f.item.physicalPath == editor.container.dataset.file,
      );
      editor.session.getValue() != file.content;
      file.isModified = editor.session.getValue() != file.original;
    });

    editor.commands.addCommand({
      name: "save",
      bindKey: { win: "Ctrl-S", mac: "Cmd-S" },
      exec: async function (editor) {
        let file = vm.files.find(
          (f) => f.item.physicalPath == editor.container.dataset.file,
        );
        await vm.saveFile(file);
      },
    });

    editor.commands.addCommand({
      name: "Format",
      exec: function () {
        let canFormat = true;
        let selection = editor.getSelectedText();
        selection = selection == "" ? null : selection;
        var beautify_js = beautify; // also available under "js" export
        var beautify_css = beautify.css;
        var beautify_html = beautify.html;
        let val = selection != null ? selection : editor.session.getValue();
        switch (editor.getSession().getMode().$id) {
          case "ace/mode/xml":
            {
              val = this.prettifyXml(val);
            }
            break;
          case "ace/mode/javascript":
            {
              val = beautify_js(val);
            }
            break;
          case "ace/mode/json":
            {
              val = beautify_js(val);
            }
            break;
          case "ace/mode/html":
          case "ace/mode/razor":
            {
              val = beautify_html(val, { wrap_attributes: "force-aligned" });
            }
            break;
          case "ace/mode/less":
          case "ace/mode/sass":
          case "ace/mode/scss":
          case "ace/mode/css":
            {
              val = beautify_css(val);
            }
            break;
          default:
            canFormat = false;
            break;
        }
        if (canFormat) {
          if (selection != null) {
            editor.session.replace(editor.selection.getRange(), val);
          } else {
            editor.session.setValue(val);
          }
        }
      },
      bindKey: { mac: "Alt-Shift-F", win: "Alt-Shift-F" },
    });
  }
  async saveFile(file) {
    if (!file.isModified) return;
    try {
      axios.post(`/manage/filesystem/file`, {
        path: file.item.physicalPath,
        content: file.content,
        type: 0,
      });
      file.original = file.content;
      file.isModified = false;
    } catch (e) {
      throw e;
    }
  }

  async uploadFile(event: any) {
    if (!this.selectedItem?.isDirectory) return;
    const url = `/manage/filesystem/binaryfile?path=${this.selectedItem.physicalPath}`;
    const parent = this.getItemByPath(this.items, this.currentDirectoryPath);
    try {
      const { data } = await axios.post(url, event.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        onUploadProgress(e) {
          event.onProgress(e, event.file);
        },
      });
      parent.children.push(data);
      parent.children.sort(
        (a, b) => b.isDirectory - a.isDirectory || a.name.localeCompare(b.name),
      );
    } catch (e) {
      throw e;
    }
  }

  getItemByPath(items, path) {
    const item = items.find((f) => f.physicalPath == path);
    if (item != null) {
      return item;
    }
    let _children = [] as any;
    items.forEach((f) => {
      _children = _children.concat(f.children);
    });
    return _children.length > 0 ? this.getItemByPath(_children, path) : null;
  }
  async createDirectory(name) {
    const parent = this.getItemByPath(this.items, this.currentDirectoryPath);
    const url = `/manage/filesystem/directory`;
    try {
      const _result = await axios.post(url, {
        path: parent.physicalPath,
        name,
      });
      parent.children.push(_result.data);
      parent.children.sort(
        (a, b) => b.isDirectory - a.isDirectory || a.name.localeCompare(b.name),
      );
      this.directoryDialog.active = false;
      this.directoryDialog.name = null;
    } catch (e) {
      throw e;
    }
  }

  async deleteFileOrDirectory() {
    let directoryPath =
      this.selectedItem.physicalPath.match(/(.*)[\/\\]/)[1] || "";
    let parent = this.getItemByPath(this.items, directoryPath);
    let url = `/manage/filesystem/${
      this.selectedItem.isDirectory ? "directory" : "file"
    }?path=${this.selectedItem.physicalPath}`;

    try {
      await axios.delete(url);
      let _index = parent.children.findIndex(
        (f) => f.physicalPath == this.selectedItem.physicalPath,
      );

      if (_index == -1) {
        return console.log("invalid index", parent, this.selectedItem);
      }

      parent.children.splice(_index, 1);
      this.selectedItem = null;
    } catch (e) {
      throw e;
    }
  }

  async createTextFile(name) {
    const parent = this.getItemByPath(this.items, this.currentDirectoryPath);
    const url = `/manage/filesystem/file`;
    try {
      const _result = await axios.post(url, {
        path: `${parent.physicalPath}/${name}`,
        content: "",
      });
      parent.children.push(_result.data);
      parent.children.sort(
        (a, b) => b.isDirectory - a.isDirectory || a.name.localeCompare(b.name),
      );
      this.activatedItems = [`${_result.data.physicalPath}`];
      this.textFileDialog.active = false;
      this.textFileDialog.name = null;
    } catch (e) {
      throw e;
    }
  }
  async showEditor(item) {
    if (item.isDirectory) return;
    if (this.getEditor(item.name) != "text") return;

    const url = `/manage/filesystem/file?path=${item.physicalPath}`;
    const fileIndex = this.files.findIndex(
      (f) => f.item.physicalPath == item.physicalPath,
    );
    if (fileIndex >= 0) {
      this.activeTab = fileIndex;
      return;
    }
    try {
      const result = await axios.get(url);
      this.files.push({
        item: item,
        content: result.data.content,
        original: result.data.content,
        isModified: false,
      });
      this.activeTab = this.files.findIndex(
        (f) => f.item.physicalPath == item.physicalPath,
      );
    } catch (e) {
      console.log(e);
    }
  }

  closeFile(file, index, e) {
    e.stopPropagation();
    this.files.splice(index, 1);
  }

  fileItemClick({ item, active }, e) {
    if (active) {
      e.stopPropagation();
    }
    this.renameFilePath = null;
    this.renameFileName = null;
    this.selectedItem = item;
  }
  getLang(fileName) {
    return this.extensitons[fileName.split(".").pop()]?.lang;
  }
  getEditor(fileName) {
    return this.extensitons[fileName.split(".").pop()]?.editor;
  }
  getIcon(fileName) {
    return this.extensitons[fileName.split(".").pop()]?.icon ?? "mdi-file";
  }
  getColor(fileName) {
    return this.extensitons[fileName.split(".").pop()]?.color ?? "gray";
  }
  async loadDirectory(path) {
    try {
      const result = await axios.get(`/manage/filesystem?path=${path}`);
      this.items = result.data;
    } catch (e) {
      console.log(e);
    }
  }
  setEvents() {
    const minSize = this.navigation.minSize;

    const leftPanel = this.$refs.leftPanel as HTMLElement;
    const border = this.$refs.border as HTMLElement;

    function resize(e) {
      document.body.style.cursor = "ew-resize";
      let leftPos = leftPanel?.parentElement?.getBoundingClientRect()?.left;
      let f = e.clientX - (leftPos ?? 0);

      leftPanel.style.flexBasis = (f > minSize ? f : minSize) + "px";
    }

    border?.addEventListener(
      "mousedown",
      (e: any) => {
        if (e.offsetX < minSize) {
          leftPanel.style.transition = "initial";
          document.addEventListener("mousemove", resize, false);
        }
      },
      false,
    );

    document.addEventListener(
      "mouseup",
      () => {
        leftPanel.style.transition = "";
        this.navigation.width = leftPanel.style.flexBasis;
        document.body.style.cursor = "";
        document.removeEventListener("mousemove", resize, false);
      },
      false,
    );
  }

  mounted() {
    this.setEvents();
    this.loadDirectory("/");
  }
}
